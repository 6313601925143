import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.routes').then(m => m.routes),
  },
  {
    path: 'fishingwaters',
    loadChildren: () =>
      import('./fishingwaters/fishing-waters.routes').then(m => m.routes),
  },
  {
    path: 'invite',
    loadChildren: () => import('./invite/invite.routes').then(m => m.routes),
  },
  {
    path: 'permits',
    loadChildren: () => import('./permits/permits.routes').then(m => m.routes),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.routes').then(m => m.routes),
  },
  {
    path: 'coupling',
    loadChildren: () =>
      import('./coupling/coupling.routes').then(m => m.routes),
  },
  {
    path: 'address',
    loadChildren: () =>
      import('./profile/address-edit/address-edit.routes').then(m => m.routes),
  },
  {
    path: 'elections',
    loadChildren: () =>
      import('./elections/elections.routes').then(m => m.routes),
  },
  {
    path: 'misconduct',
    loadChildren: () =>
      import('./misconduct/misconduct.routes').then(m => m.routes),
  },
  {
    path: '',
    loadChildren: () => import('./home/home.routes').then(m => m.routes),
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.routes').then(m => m.routes),
  },
  // {
  //   path: '**',
  //   pathMatch: 'full',
  //   redirectTo: 'error/page-not-found',
  // },
];
