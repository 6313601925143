<!--todo remove innerhtml and use different way of showing translations-->
<div [innerHTML]="'portal.footer.conditions' | translate"></div>
<br />
<p>
  <a
    (click)="showCookieSettings()"
    class="svnl-cookie-click"
    translate="portal.footer.cookies.link"
  ></a>
  <span [innerHTML]="'portal.footer.cookies.text' | translate"></span>
</p>
<br />
<div [innerHTML]="'portal.footer.contact' | translate"></div>
