import * as i0 from '@angular/core';
import { Pipe, NgModule } from '@angular/core';
import { DateTime, Duration } from 'luxon';
import { interval } from 'rxjs';
class DateTimeEarliestPipe {
  transform(value) {
    return value == null ? null : DateTime.min(...value);
  }
}
DateTimeEarliestPipe.ɵfac = function DateTimeEarliestPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeEarliestPipe)();
};
DateTimeEarliestPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeEarliest",
  type: DateTimeEarliestPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeEarliestPipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeEarliest'
    }]
  }], null, null);
})();
class DateTimeFromFormatPipe {
  transform(value, format, opts) {
    return value == null ? null : DateTime.fromFormat(value, format, opts);
  }
}
DateTimeFromFormatPipe.ɵfac = function DateTimeFromFormatPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeFromFormatPipe)();
};
DateTimeFromFormatPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeFromFormat",
  type: DateTimeFromFormatPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeFromFormatPipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeFromFormat'
    }]
  }], null, null);
})();
class DateTimeFromHttpPipe {
  transform(value, opts) {
    return value == null ? null : DateTime.fromHTTP(value, opts);
  }
}
DateTimeFromHttpPipe.ɵfac = function DateTimeFromHttpPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeFromHttpPipe)();
};
DateTimeFromHttpPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeFromHttp",
  type: DateTimeFromHttpPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeFromHttpPipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeFromHttp'
    }]
  }], null, null);
})();
class DateTimeFromIsoPipe {
  transform(value, opts) {
    return value == null ? null : DateTime.fromISO(value, opts);
  }
}
DateTimeFromIsoPipe.ɵfac = function DateTimeFromIsoPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeFromIsoPipe)();
};
DateTimeFromIsoPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeFromIso",
  type: DateTimeFromIsoPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeFromIsoPipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeFromIso'
    }]
  }], null, null);
})();
class DateTimeFromJsDatePipe {
  transform(value, opts) {
    return value == null ? null : DateTime.fromJSDate(value, opts);
  }
}
DateTimeFromJsDatePipe.ɵfac = function DateTimeFromJsDatePipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeFromJsDatePipe)();
};
DateTimeFromJsDatePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeFromJsDate",
  type: DateTimeFromJsDatePipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeFromJsDatePipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeFromJsDate'
    }]
  }], null, null);
})();
class DateTimeFromMillisecondsPipe {
  transform(value, opts) {
    return value == null ? null : DateTime.fromMillis(value, opts);
  }
}
DateTimeFromMillisecondsPipe.ɵfac = function DateTimeFromMillisecondsPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeFromMillisecondsPipe)();
};
DateTimeFromMillisecondsPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeFromMilliseconds",
  type: DateTimeFromMillisecondsPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeFromMillisecondsPipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeFromMilliseconds'
    }]
  }], null, null);
})();
class DateTimeFromRfc2822Pipe {
  transform(value, opts) {
    return value == null ? null : DateTime.fromRFC2822(value, opts);
  }
}
DateTimeFromRfc2822Pipe.ɵfac = function DateTimeFromRfc2822Pipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeFromRfc2822Pipe)();
};
DateTimeFromRfc2822Pipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeFromRfc2822",
  type: DateTimeFromRfc2822Pipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeFromRfc2822Pipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeFromRfc2822'
    }]
  }], null, null);
})();
class DateTimeFromSqlPipe {
  transform(value, opts) {
    return value == null ? null : DateTime.fromSQL(value, opts);
  }
}
DateTimeFromSqlPipe.ɵfac = function DateTimeFromSqlPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeFromSqlPipe)();
};
DateTimeFromSqlPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeFromSql",
  type: DateTimeFromSqlPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeFromSqlPipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeFromSql'
    }]
  }], null, null);
})();
class DateTimeLatestPipe {
  transform(value) {
    return value == null ? null : DateTime.max(...value);
  }
}
DateTimeLatestPipe.ɵfac = function DateTimeLatestPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeLatestPipe)();
};
DateTimeLatestPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeLatest",
  type: DateTimeLatestPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeLatestPipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeLatest'
    }]
  }], null, null);
})();
class DateTimeToFormatPipe {
  transform(value, format, opts) {
    return value == null ? null : value.toFormat(format, opts);
  }
}
DateTimeToFormatPipe.ɵfac = function DateTimeToFormatPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeToFormatPipe)();
};
DateTimeToFormatPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeToFormat",
  type: DateTimeToFormatPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeToFormatPipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeToFormat'
    }]
  }], null, null);
})();
class DateTimeToIsoDatePipe {
  transform(value, opts) {
    return value == null ? null : value.toISODate(opts);
  }
}
DateTimeToIsoDatePipe.ɵfac = function DateTimeToIsoDatePipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeToIsoDatePipe)();
};
DateTimeToIsoDatePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeToIsoDate",
  type: DateTimeToIsoDatePipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeToIsoDatePipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeToIsoDate'
    }]
  }], null, null);
})();
class DateTimeToIsoTimePipe {
  transform(value, opts) {
    return value == null ? null : value.toISOTime(opts);
  }
}
DateTimeToIsoTimePipe.ɵfac = function DateTimeToIsoTimePipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeToIsoTimePipe)();
};
DateTimeToIsoTimePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeToIsoTime",
  type: DateTimeToIsoTimePipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeToIsoTimePipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeToIsoTime'
    }]
  }], null, null);
})();
class DateTimeToIsoWeekDatePipe {
  transform(value) {
    return value == null ? null : value.toISOWeekDate();
  }
}
DateTimeToIsoWeekDatePipe.ɵfac = function DateTimeToIsoWeekDatePipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeToIsoWeekDatePipe)();
};
DateTimeToIsoWeekDatePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeToIsoWeekDate",
  type: DateTimeToIsoWeekDatePipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeToIsoWeekDatePipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeToIsoWeekDate'
    }]
  }], null, null);
})();
class DateTimeToIsoPipe {
  transform(value, opts) {
    return value == null ? null : value.toISO(opts);
  }
}
DateTimeToIsoPipe.ɵfac = function DateTimeToIsoPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeToIsoPipe)();
};
DateTimeToIsoPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeToIso",
  type: DateTimeToIsoPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeToIsoPipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeToIso'
    }]
  }], null, null);
})();
class DateTimeToJsDatePipe {
  transform(value) {
    return value == null ? null : value.toJSDate();
  }
}
DateTimeToJsDatePipe.ɵfac = function DateTimeToJsDatePipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeToJsDatePipe)();
};
DateTimeToJsDatePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeToJsDate",
  type: DateTimeToJsDatePipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeToJsDatePipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeToJsDate'
    }]
  }], null, null);
})();
class DateTimeToLocalPipe {
  transform(value) {
    return value == null ? null : value.toLocal();
  }
}
DateTimeToLocalPipe.ɵfac = function DateTimeToLocalPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeToLocalPipe)();
};
DateTimeToLocalPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeToLocal",
  type: DateTimeToLocalPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeToLocalPipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeToLocal'
    }]
  }], null, null);
})();
class DateTimeToRelativePipe {
  constructor(ref) {
    this.ref = ref;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
  transform(value, opts) {
    if (value == null) {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }
      return null;
    }
    if (!this.subscription) {
      this.subscription = interval(1000).subscribe(() => this.ref.markForCheck());
    }
    return value.toRelative(opts);
  }
}
DateTimeToRelativePipe.ɵfac = function DateTimeToRelativePipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeToRelativePipe)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef, 16));
};
DateTimeToRelativePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeToRelative",
  type: DateTimeToRelativePipe,
  pure: false,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeToRelativePipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeToRelative',
      pure: false
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();
class DateTimeToRelativeCalendarPipe {
  constructor(ref) {
    this.ref = ref;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
  transform(value, opts) {
    if (value == null) {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }
      return null;
    }
    if (!this.subscription) {
      this.subscription = interval(1000).subscribe(() => this.ref.markForCheck());
    }
    return value.toRelativeCalendar(opts);
  }
}
DateTimeToRelativeCalendarPipe.ɵfac = function DateTimeToRelativeCalendarPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeToRelativeCalendarPipe)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef, 16));
};
DateTimeToRelativeCalendarPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeToRelativeCalendar",
  type: DateTimeToRelativeCalendarPipe,
  pure: false,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeToRelativeCalendarPipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeToRelativeCalendar',
      pure: false
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();
class DateTimeToSqlPipe {
  transform(value, opts) {
    return value == null ? null : value.toSQL(opts);
  }
}
DateTimeToSqlPipe.ɵfac = function DateTimeToSqlPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeToSqlPipe)();
};
DateTimeToSqlPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeToSql",
  type: DateTimeToSqlPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeToSqlPipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeToSql'
    }]
  }], null, null);
})();
class DateTimeToLocaleStringPipe {
  transform(value, format, opts) {
    return value == null ? null : value.toLocaleString(format, opts);
  }
}
DateTimeToLocaleStringPipe.ɵfac = function DateTimeToLocaleStringPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeToLocaleStringPipe)();
};
DateTimeToLocaleStringPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeToLocaleString",
  type: DateTimeToLocaleStringPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeToLocaleStringPipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeToLocaleString'
    }]
  }], null, null);
})();
class DateTimeToUtcPipe {
  transform(value, offset, opts) {
    return value == null ? null : value.toUTC(offset, opts);
  }
}
DateTimeToUtcPipe.ɵfac = function DateTimeToUtcPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimeToUtcPipe)();
};
DateTimeToUtcPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "dateTimeToUtc",
  type: DateTimeToUtcPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeToUtcPipe, [{
    type: Pipe,
    args: [{
      name: 'dateTimeToUtc'
    }]
  }], null, null);
})();
class DurationFromIsoPipe {
  transform(value, opts) {
    return value == null ? null : Duration.fromISO(value, opts);
  }
}
DurationFromIsoPipe.ɵfac = function DurationFromIsoPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DurationFromIsoPipe)();
};
DurationFromIsoPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "durationFromIso",
  type: DurationFromIsoPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DurationFromIsoPipe, [{
    type: Pipe,
    args: [{
      name: 'durationFromIso'
    }]
  }], null, null);
})();
class DurationFromIsoTimePipe {
  transform(value, opts) {
    return value == null ? null : Duration.fromISOTime(value, opts);
  }
}
DurationFromIsoTimePipe.ɵfac = function DurationFromIsoTimePipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DurationFromIsoTimePipe)();
};
DurationFromIsoTimePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "durationFromIsoTime",
  type: DurationFromIsoTimePipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DurationFromIsoTimePipe, [{
    type: Pipe,
    args: [{
      name: 'durationFromIsoTime'
    }]
  }], null, null);
})();
class DurationFromMillisecondsPipe {
  transform(value, opts) {
    return value == null ? null : Duration.fromMillis(value, opts);
  }
}
DurationFromMillisecondsPipe.ɵfac = function DurationFromMillisecondsPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DurationFromMillisecondsPipe)();
};
DurationFromMillisecondsPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "durationFromMilliseconds",
  type: DurationFromMillisecondsPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DurationFromMillisecondsPipe, [{
    type: Pipe,
    args: [{
      name: 'durationFromMilliseconds'
    }]
  }], null, null);
})();
class DurationLongestPipe {
  transform(value) {
    return value == null ? null : [...value].sort((a, b) => b.milliseconds - a.milliseconds)[0];
  }
}
DurationLongestPipe.ɵfac = function DurationLongestPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DurationLongestPipe)();
};
DurationLongestPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "durationLongest",
  type: DurationLongestPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DurationLongestPipe, [{
    type: Pipe,
    args: [{
      name: 'durationLongest'
    }]
  }], null, null);
})();
class DurationShortestPipe {
  transform(value) {
    return value == null ? null : [...value].sort((a, b) => a.milliseconds - b.milliseconds)[0];
  }
}
DurationShortestPipe.ɵfac = function DurationShortestPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DurationShortestPipe)();
};
DurationShortestPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "durationShortest",
  type: DurationShortestPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DurationShortestPipe, [{
    type: Pipe,
    args: [{
      name: 'durationShortest'
    }]
  }], null, null);
})();
class DurationToFormatPipe {
  transform(value, format, opts) {
    return value == null ? null : value.toFormat(format, opts);
  }
}
DurationToFormatPipe.ɵfac = function DurationToFormatPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DurationToFormatPipe)();
};
DurationToFormatPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "durationToFormat",
  type: DurationToFormatPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DurationToFormatPipe, [{
    type: Pipe,
    args: [{
      name: 'durationToFormat'
    }]
  }], null, null);
})();
class DurationToHumanPipe {
  transform(value, opts) {
    return value == null ? null : value.toHuman(opts);
  }
}
DurationToHumanPipe.ɵfac = function DurationToHumanPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DurationToHumanPipe)();
};
DurationToHumanPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "durationToHuman",
  type: DurationToHumanPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DurationToHumanPipe, [{
    type: Pipe,
    args: [{
      name: 'durationToHuman'
    }]
  }], null, null);
})();
class DurationToIsoPipe {
  transform(value) {
    return value == null ? null : value.toISO();
  }
}
DurationToIsoPipe.ɵfac = function DurationToIsoPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DurationToIsoPipe)();
};
DurationToIsoPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "durationToIso",
  type: DurationToIsoPipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DurationToIsoPipe, [{
    type: Pipe,
    args: [{
      name: 'durationToIso'
    }]
  }], null, null);
})();
class DurationToIsoTimePipe {
  transform(value, opts) {
    return value == null ? null : value.toISOTime(opts);
  }
}
DurationToIsoTimePipe.ɵfac = function DurationToIsoTimePipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DurationToIsoTimePipe)();
};
DurationToIsoTimePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "durationToIsoTime",
  type: DurationToIsoTimePipe,
  pure: true,
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DurationToIsoTimePipe, [{
    type: Pipe,
    args: [{
      name: 'durationToIsoTime'
    }]
  }], null, null);
})();

/** @internal */
const PIPES = [DateTimeEarliestPipe, DateTimeFromFormatPipe, DateTimeFromHttpPipe, DateTimeFromIsoPipe, DateTimeFromJsDatePipe, DateTimeFromMillisecondsPipe, DateTimeFromRfc2822Pipe, DateTimeFromSqlPipe, DateTimeLatestPipe, DateTimeToFormatPipe, DateTimeToIsoDatePipe, DateTimeToIsoTimePipe, DateTimeToIsoWeekDatePipe, DateTimeToIsoPipe, DateTimeToJsDatePipe, DateTimeToLocalPipe, DateTimeToRelativePipe, DateTimeToRelativeCalendarPipe, DateTimeToSqlPipe, DateTimeToLocaleStringPipe, DateTimeToUtcPipe, DurationFromIsoPipe, DurationFromIsoTimePipe, DurationFromMillisecondsPipe, DurationLongestPipe, DurationShortestPipe, DurationToFormatPipe, DurationToHumanPipe, DurationToIsoPipe, DurationToIsoTimePipe];
class LuxonModule {}
LuxonModule.ɵfac = function LuxonModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LuxonModule)();
};
LuxonModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: LuxonModule
});
LuxonModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LuxonModule, [{
    type: NgModule,
    args: [{
      declarations: PIPES,
      exports: [PIPES]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DateTimeEarliestPipe, DateTimeFromFormatPipe, DateTimeFromHttpPipe, DateTimeFromIsoPipe, DateTimeFromJsDatePipe, DateTimeFromMillisecondsPipe, DateTimeFromRfc2822Pipe, DateTimeFromSqlPipe, DateTimeLatestPipe, DateTimeToFormatPipe, DateTimeToIsoDatePipe, DateTimeToIsoPipe, DateTimeToIsoTimePipe, DateTimeToIsoWeekDatePipe, DateTimeToJsDatePipe, DateTimeToLocalPipe, DateTimeToLocaleStringPipe, DateTimeToRelativeCalendarPipe, DateTimeToRelativePipe, DateTimeToSqlPipe, DateTimeToUtcPipe, DurationFromIsoPipe, DurationFromIsoTimePipe, DurationFromMillisecondsPipe, DurationLongestPipe, DurationShortestPipe, DurationToFormatPipe, DurationToHumanPipe, DurationToIsoPipe, DurationToIsoTimePipe, LuxonModule };
