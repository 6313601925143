<mat-nav-list class="svnl-mat-nav-list">
  @for (menuItem of menuItems; track menuItem) {
    <mat-list-item
      [routerLink]="[menuItem]"
      routerLinkActive="active"
      (click)="selectMenuItem()"
    >
      <mat-icon matListItemIcon>{{
        Constants[menuItem.toUpperCase() + '_MENU_ICON']
      }}</mat-icon>
      <span matListItemTitle>{{
        'portal.sidebar.menu.' + menuItem | translate
      }}</span>
    </mat-list-item>
  }

  @if (electionIds().length) {
    <mat-list-item
      [routerLink]="['elections']"
      routerLinkActive="active"
      (click)="selectMenuItem()"
    >
      <mat-icon
        matListItemIcon
        [matBadge]="electionIds().length"
        matBadgeColor="warn"
        >{{ Constants.ELECTIONS_ICON }}</mat-icon
      >
      <span matListItemTitle>{{
        'portal.sidebar.menu.elections' | translate
      }}</span>
    </mat-list-item>
  }
</mat-nav-list>
