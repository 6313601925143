import { Component, inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { SvvwAuthenticationService } from "@service/authentication.service";

@Component({
  selector: 'svnl-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  imports: [MatIcon, TranslateModule, MatButton],
})
export class LogoutComponent {
  private keycloakService = inject(SvvwAuthenticationService)

 async logout(): Promise<void> {
    await this.keycloakService.logout();
  }

}
