import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  isOpen = false;

  toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }
}
