<div class="svnl-header-toolbar">
  <img src="assets/images/logo.png" alt="Logo van sportvisserij Nederland" />
  <div id="toolbar-user-logout">
    @if (profile(); as profile) {
      <div id="profile">
        <div id="username">
          <span>{{ profile.firstName }}</span>

          @if (profile.infix) {
            <span>{{ profile.infix }}</span>
          }

          @if (profile.lastName) {
            <span>{{ profile.lastName }}</span>
          }
        </div>
        <svnl-logout></svnl-logout>
      </div>

      <!-- Hide on mobile -->
      <!--<svnl-language-select></svnl-language-select>-->

      <!-- Show on mobile -->
      <i
        class="fa-solid fa-bars fa-xl"
        id="toggle-menu"
        (click)="toggleMenu()"
      ></i>
    }
  </div>
</div>
