import { Component, inject, OnInit, signal } from '@angular/core';
import { ElectionService } from '@service/election.service';
import { MenuService } from '@service/menu.service';
import { catchError, of, tap } from 'rxjs';
import { MatListItem, MatNavList } from '@angular/material/list';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatBadge } from '@angular/material/badge';
import { Constants } from '../../constants';

@Component({
  selector: 'svnl-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  imports: [
    MatNavList,
    MatListItem,
    MatIcon,
    TranslateModule,
    RouterLink,
    MatBadge,
    RouterLinkActive,
  ],
})
export class MenuComponent implements OnInit {
  menuItems: string[] = [
    'profile',
    'permits',
    'fishingwaters',
    'invite',
    'contact',
    'misconduct',
  ];

  electionIds = signal<number[]>([]);

  private menuService = inject(MenuService);
  private electionService = inject(ElectionService);

  ngOnInit(): void {
    this.electionService
      .getElectionIds()
      .pipe(
        tap(item => this.electionIds.set(item)),
        catchError(error => {
          console.error('Error getting electionId[]:', error);

          return of(null);
        }),
      )
      .subscribe();
  }

  selectMenuItem(): void {
    this.menuService.toggleMenu();
  }

  protected readonly Constants = Constants;
}
