import { Component, inject, OnInit, signal } from '@angular/core';
import { DialogService } from '@service/dialog.service';
import { TranslateModule } from '@ngx-translate/core';
import { Constants } from '../../constants';

@Component({
  selector: 'svnl-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
  imports: [TranslateModule],
})
export class PageFooterComponent implements OnInit {
  showCookies = signal<boolean>(false);
  svnlSeenCookieStatement = signal<string>('false');

  private dialogService = inject(DialogService);

  ngOnInit(): void {
    const storedCookie = localStorage.getItem(Constants.SEEN_COOKIE_STATEMENT);

    if (storedCookie) {
      this.svnlSeenCookieStatement.set(storedCookie);
    }

    if (this.svnlSeenCookieStatement() === 'false') {
      this.dialogService.openCookieFooter();
      this.svnlSeenCookieStatement.set('true');
      localStorage.setItem(
        Constants.SEEN_COOKIE_STATEMENT,
        this.svnlSeenCookieStatement(),
      );
    }
  }

  showCookieSettings(): void {
    this.dialogService.openCookieFooter();
    this.showCookies.set(true);
  }
}
