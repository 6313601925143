import { Component, inject, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AnalyticsService } from '@service/analytics.service';
import { MenuService } from '@service/menu.service';
import { filter, map } from 'rxjs';
import { PageFooterComponent } from '@component/page-footer/page-footer.component';
import { NgClass } from '@angular/common';
import { MenuComponent } from '@component/menu/menu.component';
import { PageHeaderComponent } from '@component/page-header/page-header.component';

@Component({
  selector: 'svnl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    PageFooterComponent,
    RouterOutlet,
    NgClass,
    MenuComponent,
    PageHeaderComponent,
  ],
})
export class AppComponent {
  @ViewChild('gridWrapper') gridWrapper;
  @ViewChild('scrollWrapper') scrollWrapper;

  private menuService = inject(MenuService);
  private analyticsService = inject(AnalyticsService);
  private router = inject(Router);

  constructor() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((item: NavigationEnd) => {
          this.analyticsService.pageView({ event: 'page', label: item.url });
        }),
      )
      .subscribe(() => {
        setTimeout(() => {
          this.scrollWrapper.nativeElement.scrollTop = 0;
        }, 0);
      });
  }

  showMenu(): boolean {
    return this.menuService.isOpen;
  }
}
