import { Component, inject, OnInit, signal } from '@angular/core';
import { tap } from 'rxjs';

import { Profile } from '@model/profile';
import { MenuService } from '@service/menu.service';
import { ProfileService } from '@service/profile.service';
import { LogoutComponent } from '@component/logout/logout.component';

@Component({
  selector: 'svnl-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  imports: [LogoutComponent],
})
export class PageHeaderComponent implements OnInit {
  profile = signal<Profile | undefined>(undefined);

  private profileService = inject(ProfileService);
  private menuService = inject(MenuService);

  ngOnInit(): void {
    this.profileService
      .getProfile(false)
      .pipe(tap(profile => this.profile.set(profile)))
      .subscribe();
  }

  toggleMenu(): void {
    this.menuService.toggleMenu();
  }
}
