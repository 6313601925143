import {
  enableProdMode,
  importProvidersFrom,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  provideHttpClient, withFetch, withInterceptors,
} from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { LuxonModule } from 'luxon-angular';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { ToastrModule } from 'ngx-toastr';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { provideRouter } from '@angular/router';
import { HttpBackend } from '@angular/common/http';
import { AuthenticationService, SVNL_SHARED_CONFIG, SvnlSharedConfig, tokenInterceptor } from '@svnl/shared';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { SvvwAuthenticationService } from "@service/authentication.service";
import { Location } from '@angular/common';

if (environment.production) {
  enableProdMode();
}

const svnlSharedConfig: SvnlSharedConfig = {
  serverUrl: environment.serverUrl,
  authentication: {
    settings: environment.authentication.settings,
    debugLogging: false,
  },
  production: environment.production,
};

const initializeAuthenticationService = (
  authenticationService: SvvwAuthenticationService,
  location: Location,
) => {
  return async (): Promise<void> =>
    await authenticationService.init(location.path());
};

const multiTranslateHttpLoader = (httpBackend: HttpBackend) =>
  new MultiTranslateHttpLoader(httpBackend, [
    { prefix: './assets/translate/', suffix: '.json' },
    { prefix: './assets/translate/countries/', suffix: '.json' },
  ]);

const prodOnlyImports = [
  NgxGoogleAnalyticsModule.forRoot(environment.analytics.ga.code),
  NgxGoogleAnalyticsRouterModule,
  GoogleTagManagerModule.forRoot({
    id: environment.analytics.gtm.code,
  }),
];

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: AuthenticationService,
      useExisting: SvvwAuthenticationService,
    },
    { provide: SVNL_SHARED_CONFIG, useValue: svnlSharedConfig },
    importProvidersFrom(
      BrowserAnimationsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: multiTranslateHttpLoader,
          deps: [HttpBackend],
        },
        defaultLanguage: 'nl',
      }),
      LuxonModule,
      ToastrModule.forRoot({
        positionClass: 'toast-bottom-center',
      }),
      MatLuxonDateModule,
      ...prodOnlyImports,
    ),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'nl',
    },
    provideAppInitializer(() => {
      const initializerFn = initializeAuthenticationService(
        inject(SvvwAuthenticationService),
        inject(Location),
      );

      return initializerFn();
    }),
    provideRouter(routes),
    provideHttpClient(withFetch(), withInterceptors([tokenInterceptor])),
  ],
}).catch(err => console.error(err));
